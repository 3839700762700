import * as React from "react"

function MongoDB(props) {
  return (
    <svg
      id="prefix__Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 360 360"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {".prefix__st0{fill-rule:evenodd;clip-rule:evenodd;fill:#fff}"}
      </style>
      <path
        className="prefix__st0"
        d="M188.19 360l-9.58-3.27s1.17-48.86-16.36-52.36c-11.69-13.56 1.87-575.06 43.95-1.87 0 0-14.49 7.25-17.06 19.64-2.82 12.15-.95 37.86-.95 37.86z"
      />
      <path
        d="M188.19 360l-9.58-3.27s1.17-48.86-16.36-52.36c-11.69-13.56 1.87-575.06 43.95-1.87 0 0-14.49 7.25-17.06 19.64-2.82 12.15-.95 37.86-.95 37.86z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#a6a385"
      />
      <path
        className="prefix__st0"
        d="M193.34 312.78s83.92-55.17 64.29-169.95c-18.93-83.45-63.59-110.8-68.49-121.32C183.76 14.03 178.62.94 178.62.94l3.51 232.13c-.01.23-7.26 71.06 11.21 79.71"
      />
      <path
        d="M193.34 312.78s83.92-55.17 64.29-169.95c-18.93-83.45-63.59-110.8-68.49-121.32C183.76 14.03 178.62.94 178.62.94l3.51 232.13c-.01.23-7.26 71.06 11.21 79.71"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#499d4a"
      />
      <path
        className="prefix__st0"
        d="M173.7 315.82s-78.78-53.77-74.1-148.44c4.44-94.68 60.08-141.19 70.83-149.61 7.01-7.48 7.25-10.29 7.72-17.77 4.91 10.52 3.97 157.32 4.67 174.62 2.1 66.63-3.74 128.58-9.12 141.2z"
      />
      <path
        d="M173.7 315.82s-78.78-53.77-74.1-148.44c4.44-94.68 60.08-141.19 70.83-149.61 7.01-7.48 7.25-10.29 7.72-17.77 4.91 10.52 3.97 157.32 4.67 174.62 2.1 66.63-3.74 128.58-9.12 141.2z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#58aa50"
      />
    </svg>
  )
}

export default MongoDB;