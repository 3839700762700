import * as React from "react"

function Express(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 360 360" {...props}>
      <path fill={props.lightMode ? 'black' : 'white'} d="M360 278.83c-10.84 4.11-23.05-.53-28.42-10.81-16.31-24.42-34.33-47.74-51.64-71.56l-7.51-10.01c-20.62 27.62-41.23 54.15-60.05 81.67-5.07 9.81-16.63 14.35-27.02 10.61l77.36-103.79-71.96-93.78c10.68-3.86 22.6.33 28.52 10.01 16.71 24.42 35.23 47.74 53.64 72.46 18.52-24.52 36.83-47.94 53.94-72.16 5.1-9.66 16.64-13.97 26.82-10.01l-27.92 37.03c-12.51 16.51-24.82 33.13-37.83 49.24a10.001 10.001 0 00-.78 14.13c.25.27.51.53.78.78 23.93 31.73 47.65 63.56 72.07 96.19zM0 173.64c2.1-10.01 3.4-20.92 6.31-31.13 17.31-61.55 87.87-87.17 136.41-49.04 28.42 22.32 35.53 53.94 34.13 89.57H16.71c-2.6 63.65 43.34 102.09 102.09 82.47a60.95 60.95 0 0038.73-43.14c3.1-10.01 8.11-11.71 17.61-8.81a81.242 81.242 0 01-38.83 59.35c-35.71 19.53-79.94 13.88-109.59-14.01A98.578 98.578 0 012.1 201.05c0-3.4-1.3-6.81-2-10.01-.07-5.92-.1-11.73-.1-17.4zm16.91-4.3h144.82c-.9-46.14-30.03-78.87-68.86-79.17-43.24-.6-74.16 31.43-76.06 78.97l.1.2z" />
    </svg>
  )
}

export default Express;