import * as React from "react"

function Node(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 360 360" {...props}>
      <path
        d="M180 360c-4.96 0-9.59-1.32-13.89-3.64l-43.99-26.13c-6.61-3.64-3.31-4.96-1.32-5.62 8.93-2.98 10.58-3.64 19.84-8.93.99-.66 2.32-.33 3.31.33l33.73 20.17c1.32.66 2.98.66 3.97 0l131.96-76.4c1.32-.66 1.98-1.98 1.98-3.64V103.68c0-1.65-.66-2.98-1.98-3.64L181.65 23.98c-1.32-.66-2.98-.66-3.97 0L45.72 100.05c-1.32.66-1.98 2.32-1.98 3.64v152.47c0 1.32.66 2.98 1.98 3.64l36.05 20.84c19.51 9.92 31.75-1.65 31.75-13.23v-150.5c0-1.98 1.65-3.97 3.97-3.97h16.87c1.98 0 3.97 1.65 3.97 3.97V267.4c0 26.13-14.22 41.34-39.03 41.34-7.61 0-13.56 0-30.43-8.27l-34.73-19.84c-8.6-4.96-13.89-14.22-13.89-24.14V104.01c0-9.92 5.29-19.18 13.89-24.14L166.1 3.47c8.27-4.63 19.51-4.63 27.78 0l131.96 76.4c8.6 4.96 13.89 14.22 13.89 24.14v152.47c0 9.92-5.29 19.18-13.89 24.14l-131.96 76.4c-4.29 1.99-9.25 2.98-13.88 2.98zm40.68-104.84c-57.88 0-69.78-26.46-69.78-48.95 0-1.98 1.65-3.97 3.97-3.97h17.2c1.98 0 3.64 1.32 3.64 3.31 2.65 17.53 10.25 26.13 45.31 26.13 27.78 0 39.69-6.28 39.69-21.17 0-8.6-3.31-14.88-46.63-19.18-36.05-3.64-58.54-11.58-58.54-40.35 0-26.79 22.49-42.66 60.19-42.66 42.33 0 63.17 14.55 65.82 46.3 0 .99-.33 1.98-.99 2.98-.66.66-1.65 1.32-2.65 1.32h-17.2c-1.65 0-3.31-1.32-3.64-2.98-3.97-18.19-14.22-24.14-41.34-24.14-30.43 0-34.07 10.58-34.07 18.52 0 9.59 4.3 12.57 45.31 17.86 40.68 5.29 59.86 12.9 59.86 41.34-.33 29.1-24.15 45.64-66.15 45.64z"
        fill="#539e43"
      />
    </svg>
  )
}

export default Node;