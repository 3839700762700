import * as React from "react"

function Box(props) {
  return (
    <svg
      id="prefix__Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 360 360"
      xmlSpace="preserve"
      {...props}
    >
      <style>{".prefix__st5{fill:#d69f6e}"}</style>
      <path fill="#d49364" d="M180 66.4l136.16 55.97v181.66L180 248.04z" />
      <path fill="#b58050" d="M180 360l136.16-55.97V122.37L180 178.36z" />
      <path fill="#b07844" d="M180 66.4L43.84 122.37v181.66L180 248.04z" />
      <path fill="#d49f77" d="M180 360L43.84 304.03V122.37L180 178.36z" />
      <path
        fill="#f2c398"
        d="M136.16 244.76L0 188.79l43.84-66.42L180 178.36z"
      />
      <path
        className="prefix__st5"
        d="M223.84 244.76L360 188.79l-43.84-66.42L180 178.36zM136.16 0L0 55.97l43.84 66.4L180 66.4z"
      />
      <path fill="#f2bf91" d="M223.84 0L360 55.97l-43.84 66.4L180 66.4z" />
      <path
        d="M129.74 322.84c-.92.97-.92-1.95-1.85-.97-.92 1.95-.92 3.89-1.85 4.87 0-6.81-5.53-9.73-6.45-15.55-5.54 1.95-3.7 11.66-7.39 6.81-1.85-1.95-.92-7.79-2.76-11.68-1.85-2.9-2.77 4.87-5.54 0-.92 1.95-1.85 2.92-1.85 4.87 0-1.95-.92-1.95-1.85-2.92 0 1.95-.92 2.92-.92 4.87-2.76-1.95-1.83-6.81-4.61-6.81-.34.37-.43.99-.49 1.69v16.71l38.79 15.96v-24.38c-.12-.41-.28-.81-.46-1.22 0 2.9-1.85 4.83-2.77 7.75z"
        fill="#d9b293"
      />
    </svg>
  )
}

export default Box;