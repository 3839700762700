import * as React from "react"

function LinkedIn(props) {
  return (
    <svg
      id="prefix__Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 360 360"
      xmlSpace="preserve"
      {...props}
    >
      <style>{".prefix__st1{fill:#fff}"}</style>
      <path
        d="M333.37-.01H26.55C11.9-.01 0 11.62 0 25.95v308.07C0 348.36 11.9 360 26.55 360h306.81c14.68 0 26.63-11.64 26.63-25.98V25.95C360 11.62 348.05-.01 333.37-.01z"
        fill="#007bb5"
      />
      <path
        className="prefix__st1"
        d="M53.34 134.96h53.45v171.8H53.34v-171.8zm26.74-85.4c17.08 0 30.95 13.87 30.95 30.96 0 17.1-13.87 30.97-30.95 30.97-17.15 0-30.98-13.87-30.98-30.97 0-17.09 13.83-30.96 30.98-30.96M140.29 134.96h51.18v23.49h.73c7.12-13.51 24.54-27.75 50.52-27.75 54.06 0 64.05 35.57 64.05 81.84v94.22h-53.39v-83.55c0-19.92-.34-45.55-27.74-45.55-27.78 0-32.02 21.71-32.02 44.13v84.97h-53.33v-171.8z"
      />
    </svg>
  )
}

export default LinkedIn;