import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      id="prefix__Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 216 997.25"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {
          ".prefix__st01315{fill:none}.prefix__st1134144{fill:#fed330}.prefix__st2{fill:#4bcffa}.prefix__st3{fill:#fd9644}"
        }
      </style>
      <path fill={props.lightMode ? '#000' : '#fff'} d="M22.01 14.9H0V1.47h58.46V14.9H36.45v60.38H22.01V14.9zM65 30.81C65 11.85 76.18 0 96.38 0s31.37 11.85 31.37 30.81v44.46H113.3V30.81c0-10.95-5.64-17.38-16.93-17.38s-16.93 6.43-16.93 17.38v44.46H65V30.81zM137.45 38.37C137.45 16.25 153.7 0 176.73 0S216 16.25 216 38.37s-16.25 38.37-39.27 38.37-39.28-16.25-39.28-38.37zm63.88 0c0-14.45-9.93-24.94-24.6-24.94-14.67 0-24.6 10.5-24.6 24.94s9.93 25.05 24.6 25.05c14.67.01 24.6-10.6 24.6-25.05zM0 94.25h17.22v2.37H2.58v8h12.55v2.37H2.58v8.36h14.64v2.37H0V94.25zM38.18 94.25h2.87l7.89 20.48 7.89-20.48h2.8l-9.36 23.46H47.5l-9.32-23.46zM80.59 94.25h17.22v2.37H83.17v8h12.55v2.37H83.17v8.36h14.64v2.37H80.59V94.25zM118.77 103.47c0-5.92 3.52-9.69 9.86-9.69 6.31 0 9.83 3.77 9.83 9.69v14.24h-2.58v-14.24c0-4.52-2.51-7.32-7.25-7.32-4.77 0-7.28 2.8-7.28 7.32v14.24h-2.58v-14.24zM167.38 96.62h-7.96v-2.37h18.51v2.37h-7.96v21.09h-2.58V96.62zM198.89 111.11h2.55c.11 2.98 2.3 4.7 6.03 4.7s6.03-1.83 6.03-4.7c0-3.09-2.98-3.62-6.17-4.09-3.8-.54-7.89-1.26-7.89-6.24 0-4.16 3.23-7 8.07-7 4.74 0 7.89 2.69 8.04 6.78h-2.47c-.14-2.69-2.19-4.41-5.56-4.41-3.41 0-5.56 1.79-5.56 4.56 0 3.01 2.94 3.51 6.13 4.02 3.8.61 7.93 1.29 7.93 6.31 0 4.38-3.37 7.14-8.54 7.14-5.11 0-8.48-2.69-8.59-7.07z" />
      <g>
        <path fill={props.lightMode ? '#000' : '#fff'} d="M50.05 751.69h-3.89c-.03-1.74-.05-3.48-.05-5.29h2.27l.24 1.62h1.21v-4.48h-1.21l-.26 1.64h-2.25c0-1.63.02-3.24.05-4.83h3.48l.41 2.41h1.74l-.15-3.59H41v1.12l1.55.16c.03 1.72.05 3.46.05 5.18v.77c0 1.75-.02 3.47-.05 5.18l-1.55.15v1.12h11.05l.15-3.59h-1.74l-.41 2.43zM75.32 739.17h-2.09v1.08l1.64.26-2.88 8.52-2.85-8.57 1.77-.21v-1.08H64.19v1.12l1.21.13 4.47 12.47h2.04l4.29-12.36 1.54-.24v-1.12h-1.07zM99 751.69h-3.89c-.03-1.74-.05-3.48-.05-5.29h2.27l.24 1.62h1.21v-4.48h-1.21l-.26 1.64h-2.25c0-1.63.02-3.24.05-4.83h3.48l.41 2.41h1.74l-.15-3.59H89.96v1.12l1.55.16c.03 1.72.05 3.46.05 5.18v.77c0 1.75-.02 3.47-.05 5.18l-1.55.16v1.12h11.05l.15-3.59h-1.74l-.42 2.42zM124.6 739.17h-2.38v1.12l2.38.27v7.02l-6.12-8.41h-4.7v1.14l1.43.12.31.43v10.63l-1.77.24v1.12h5.49v-1.12l-2.38-.27v-8.74l7.32 10.17h1.77v-12.34l1.77-.26v-1.12h-1.77zM148.21 739.17h-7.91l-.08 3.87h1.74l.38-2.69h2.27c.03 1.76.05 3.52.05 5.28v.77c0 1.75-.02 3.46-.05 5.16l-1.76.18v1.12h7.13v-1.12l-1.78-.18c-.03-1.72-.05-3.45-.05-5.17v-.76c0-1.79.02-3.54.05-5.28h2.28l.38 2.69h1.72l-.06-3.87h-4.31zM171.25 744.59l-.98-.45c-1.23-.55-1.89-1.13-1.89-2.23 0-1.14.96-1.89 2.15-1.89.51 0 .96.1 1.4.29l.4 2.45h1.91l.17-2.92c-1.02-.67-2.45-1.06-3.94-1.06-3.15 0-5.17 1.52-5.17 4.02 0 2.3 1.41 3.54 3.87 4.66l.88.4c1.25.56 1.8 1.05 1.8 2.15 0 1.13-.8 1.98-2.28 1.98-.78 0-1.4-.14-2.04-.39l-.41-2.56h-1.94l-.12 3.1c1.2.74 2.96 1.09 4.52 1.09 3.56 0 5.44-1.74 5.44-4.2-.02-2.09-1.12-3.24-3.77-4.44z" />
        <path
          className="prefix__st01315"
          d="M108.96 698.63H94.62v18.97h66.19v-18.97zM127.93 641.28h-4.63v18.97h38.1v-18.97h-23.76zM174.99 669.96h.15v18.97h-.15zM113.59 669.96h-4.63v18.97H161.4v-18.97h-38.1z"
        />
        <path
          className="prefix__st1134144"
          d="M127.93 641.28h-4.63v18.97h38.1v-18.97h-23.76zM108.96 698.63H94.62v18.97h66.78v-18.97h-.59zM113.59 669.96h-4.63v18.97H161.4v-18.97h-38.1zM137.64 612.61h23.76v18.97h-23.76z"
        />
        <path
          className="prefix__st01315"
          d="M127.93 641.28h-4.63v18.97h38.1v-18.97h-23.76zM137.64 612.61h23.76v18.97h-23.76zM108.96 698.63H94.62v18.97h66.78v-18.97h-.59zM113.59 669.96h-4.63v18.97H161.4v-18.97h-38.1z"
        />
        <path
          className="prefix__st2"
          d="M87.74 522.13c-11.21 0-20.33 9.12-20.33 20.32 0 11.21 9.12 20.33 20.33 20.33s20.33-9.12 20.33-20.33c0-11.2-9.12-20.32-20.33-20.32z"
        />
        <path
          className="prefix__st01315"
          d="M113.59 669.96h-4.63v18.97H161.4v-18.97h-38.1zM137.64 612.61h23.76v18.97h-23.76zM54.81 560.84c.02-.27.05-.54.07-.8-.03.26-.05.53-.07.8zM108.96 698.63H94.62v18.97h66.78v-18.97h-.59zM127.93 641.28h-4.63v18.97h38.1v-18.97h-23.76z"
        />
        <path
          className="prefix__st3"
          d="M107.99 511.79c-3.05 0-6.04.29-8.95.79 12.07 4.58 20.68 16.22 20.68 29.88 0 17.63-14.34 31.98-31.98 31.98-14.94 0-27.48-10.31-30.97-24.17-.6 2.06-1.1 4.16-1.45 6.31-.19 1.14-.33 2.3-.44 3.47-.03.27-.05.54-.07.8-.1 1.18-.17 2.37-.19 3.57l-.01.95c0 .12.01.24.01.35V717.6h30.3v-28.68h14.34v-28.68h14.34v-28.68h14.34v-28.68h33.47v-35.97l-.04-2.53c-.42-28.99-24.36-52.59-53.38-52.59z"
        />
        <path fill={props.lightMode ? '#000' : '#fff'} d="M54.62 564.41zM174.95 564.22c-.52-36.55-30.29-66.02-66.96-66.02s-66.44 29.47-66.96 66.02h-.01v.3c0 .22-.02.44-.02.66 0 .22.01.44.02.66v165.35h133.97v-167h-.04zm-87.21-42.09c11.21 0 20.33 9.12 20.33 20.32 0 11.21-9.12 20.33-20.33 20.33s-20.33-9.12-20.33-20.33c.01-11.2 9.13-20.32 20.33-20.32zM161.4 717.6H94.62v-18.97h66.78v18.97zm0-28.67H108.96v-18.97h52.44v18.97zm0-28.68h-38.1v-18.97h38.1v18.97zm0-28.67h-23.76v-18.97h23.76v18.97zm0-28.68h-33.47v28.68h-14.34v28.68H99.25v28.68H84.91v28.68h-30.3V565.7c0-.11 0-.23-.01-.35l.01-.95c.02-1.2.09-2.39.19-3.57.02-.27.05-.54.07-.8.09-.96.22-1.9.36-2.85.08-.52.17-1.04.26-1.56l.27-1.38c.09-.43.18-.86.28-1.28.22-.91.45-1.81.71-2.71 3.5 13.87 16.04 24.17 30.97 24.17 17.63 0 31.98-14.34 31.98-31.98 0-13.65-8.62-25.3-20.68-29.88 2.92-.5 5.9-.79 8.95-.79 29.02 0 52.96 23.61 53.37 52.62l.04 2.53v35.98zM99.02 491.03h5.52v-2.59l-5.52-.65v-20.25L116 491.12h4.09v-28.61l4.1-.6v-2.6H111.46v2.6l5.52.61v16.29l-14.21-19.5h-10.9v2.63l3.32.28.72 1v24.64l-4.1.58v2.59h4.1z" />
        <g>
          <path fill={props.lightMode ? '#000' : '#fff'} d="M18.53 540.93H.18L0 549.91h4.03l.88-6.25h5.29c.08 4.08.08 8.17.08 12.24v1.79c0 4.03 0 8.03-.08 11.95l-4.12.42v2.59h16.54v-2.59l-4.16-.41c-.07-3.99-.08-7.99-.08-11.99v-1.75c0-4.13 0-8.22.08-12.24h5.32l.88 6.25h3.99l-.14-8.98h-9.98zM185.36 556.79c0 11.25 7.36 16.76 15.33 16.76S216 567.92 216 556.79c0-11.25-7.39-16.76-15.32-16.76-7.92 0-15.32 5.63-15.32 16.76zm22.09 0c0 8.01-1.91 13.96-6.76 13.96s-6.78-5.95-6.78-13.96c0-7.97 1.92-13.99 6.78-13.99s6.76 6.02 6.76 13.99z" />
        </g>
      </g>
      <g>
        <path fill={props.lightMode ? '#000' : '#fff'} d="M26.87 357.45l-1.04 1.21 1.53 1.63c-1.57 1.89-3.18 3.77-4.78 5.62l-.72.83c-1.63 1.89-3.25 3.74-4.87 5.54L15.15 371l-1.04 1.21 3.61 3.13 1.95 1.69 3.97 3.44 2.38 2.06 3.5-3.73-1.87-1.62-2.71 2.23-4.21-3.64c1.58-1.91 3.21-3.8 4.89-5.74l2.45 2.12-1.25 1.97 1.3 1.13 4.18-4.84-1.3-1.13-1.8 1.53-2.43-2.1c1.52-1.76 3.04-3.49 4.55-5.17l3.76 3.25-1.8 2.99 1.87 1.62 3.19-4.01-7.86-6.79-3.61-3.15zM67.84 383.66l-1.75-.8-2.72-1.23-.63 1.39 1.97 1.31-8.78 9.37 1.37-12.82 2.43.77.63-1.39-2.85-1.3-4.88-2.22-.99-.45-.66 1.45 1.49.87-1.57 18.84 2.64 1.2 12.88-13.5 2.15.59.66-1.45-1.39-.63zM96.09 390.02l-7.17-1-2.56-.36-2.18-.3-.22 1.58 2.17.54c-.29 2.44-.62 4.89-.96 7.32l-.15 1.09c-.34 2.47-.7 4.9-1.07 7.3l-2.24-.08-.22 1.58 4.73.66 2.56.36 8.32 1.16.91-5.04-2.45-.34-1.06 3.34-5.51-.77c.29-2.46.63-4.94.98-7.47l3.21.45.02 2.33 1.71.24.88-6.33-1.71-.24-.68 2.26-3.19-.44c.32-2.3.65-4.58 1.01-6.81l4.93.69.11 3.48 2.45.34.5-5.09-3.12-.45zM135.97 389.22l-.25-1.57-2.49.38-1.89.3-3.36.52.25 1.58 3.41-.15 1.54 9.9-10.48-10.51-4.17.65-2.45.38.25 1.6 2.04-.14.53.54 2.33 14.97-2.44.74.25 1.57 2.49-.38 1.89-.3 3.36-.52-.25-1.58-3.41.14-1.92-12.31 12.55 12.72 2.49-.38-2.71-17.39zM168.75 393.55c-1.12-2.18-2.22-4.39-3.31-6.6l-.48-.97c-1.12-2.28-2.22-4.54-3.27-6.78l2.93-1.44 2.18 3.21 2.2-1.08-2.51-4.92-2.82 1.39-6.6 3.25-.73.36-5.5 2.7 2.34 5 2.22-1.09-1.21-3.69 2.91-1.43c1.15 2.23 2.27 4.48 3.38 6.73l.48.99c1.1 2.23 2.17 4.44 3.19 6.63l-2.15 1.34.7 1.43 9.13-4.49-.7-1.43-2.38.89zM199.61 366.79c-2.01-2.24-4.26-2.41-8.22-1.15l-1.46.46c-1.84.59-3.08.61-4.13-.56-1.09-1.21-.79-2.92.47-4.05.54-.49 1.11-.81 1.76-1.03l2.75 2.22 2.03-1.82-2.6-3.26c-1.71.26-3.61 1.21-5.18 2.63-3.35 3.01-4.04 6.54-1.66 9.19 2.19 2.44 4.87 2.41 8.54 1.26l1.32-.41c1.86-.61 2.91-.59 3.96.57 1.08 1.2 1.04 2.87-.53 4.28-.83.74-1.61 1.2-2.52 1.55l-2.89-2.33-2.06 1.85 2.82 3.4c1.98-.36 4.18-1.67 5.83-3.15 3.78-3.41 4.11-7.05 1.77-9.65z" />
        <path fill={props.lightMode ? '#000' : '#fff'} d="M108 167.5c-59.65 0-108 48.35-108 108s48.35 108 108 108 108-48.35 108-108-48.35-108-108-108zm103.2 108c0 27.57-10.73 53.48-30.23 72.97-3.82 3.82-7.89 7.29-12.16 10.42v.01l-.01-.01c-17.55 12.85-38.64 19.81-60.81 19.81s-43.26-6.95-60.81-19.81l-.01.01v-.01c-4.27-3.13-8.34-6.6-12.16-10.42C15.53 328.99 4.8 303.07 4.8 275.5c0-11.07 1.76-21.86 5.09-32.08l-.29-.21h.36c4.98-15.16 13.45-29.07 25.07-40.68 19.45-19.45 45.28-30.17 72.78-30.22l.2-.6.2.6c27.49.05 53.33 10.78 72.78 30.22 11.61 11.61 20.09 25.51 25.07 40.68h.36l-.29.21c3.31 10.23 5.07 21.02 5.07 32.08z" />
        <path
          className="prefix__st1134144"
          d="M108 306.33l-47.91 34.81 18.3-56.33-47.92-34.82H89.7l18.3-56.33 18.3 56.33h59.23l-47.92 34.82 18.3 56.34z"
        />
        <path
          className="prefix__st2"
          d="M108 215.61l10.33 31.79 3.05 9.37h43.29l-27.04 19.65-7.97 5.79 3.04 9.37 10.33 31.79-27.04-19.65-7.97-5.79-7.97 5.79-27.04 19.65 10.33-31.79 3.04-9.37-7.97-5.79-27.04-19.65h43.29l3.05-9.37L108 215.61m0-43.31h-.2l-23.03 70.9H9.96c-.02.07-.05.14-.07.21l60.52 43.98-23.23 71.49h.01L108 314.71l60.81 44.19h.01l-23.23-71.49 60.52-43.98c-.02-.07-.05-.14-.07-.21h-74.81l-23.03-70.9c-.07-.01-.13-.02-.2-.02z"
        />
        <path fill={props.lightMode ? '#000' : '#fff'} d="M108 193.66l16.78 51.65 1.52 4.69h59.23l-43.93 31.92-3.99 2.9 1.52 4.69 16.78 51.65-43.93-31.92-3.99-2.9-3.99 2.9-43.93 31.92 16.78-51.65 1.52-4.69-3.99-2.9L30.45 250h59.23l1.52-4.69 16.8-51.65m0-21.36h-.2l-23.03 70.9H9.96c-.02.07-.05.14-.07.21l60.52 43.98-23.23 71.49h.01L108 314.71l60.81 44.19h.01l-23.23-71.49 60.52-43.98c-.02-.07-.05-.14-.07-.21h-74.81l-23.03-70.9c-.07-.01-.13-.02-.2-.02z" />
        <path
          className="prefix__st1134144"
          d="M9.6 243.21l.29.21c.02-.07.05-.14.07-.21H9.6zM47.19 358.9v-.01zM168.81 358.9v-.01.01zM206.04 243.21c.02.07.05.14.07.21l.29-.21h-.36zM108.2 172.31l-.2-.6-.2.6h.4z"
        />
        <g>
          <path
            className="prefix__st3"
            d="M47.19 358.9c17.55 12.85 38.65 19.8 60.81 19.8 22.17 0 43.26-6.95 60.81-19.81L108 314.71 47.19 358.9zM9.89 243.42A103.498 103.498 0 004.8 275.5c0 27.57 10.73 53.48 30.23 72.97 3.82 3.82 7.89 7.29 12.16 10.42l23.23-71.49-60.53-43.98zM206.04 243.21c-4.98-15.16-13.45-29.07-25.07-40.68-19.45-19.45-45.28-30.17-72.78-30.22l23.03 70.9h74.82zM107.8 172.31c-27.49.05-53.33 10.78-72.78 30.22-11.61 11.61-20.09 25.51-25.07 40.68h74.81l23.04-70.9zM206.11 243.42l-60.52 43.98 23.23 71.49c4.27-3.13 8.34-6.6 12.16-10.42 19.49-19.49 30.23-45.41 30.23-72.97-.01-11.06-1.77-21.85-5.1-32.08z"
          />
        </g>
        <g>
          <path fill={props.lightMode ? '#000' : '#fff'} d="M65.43 201.37H46.89l-.18 9.07h4.07l.89-6.31h5.34c.08 4.12.08 8.26.08 12.37v1.81c0 4.07 0 8.12-.08 12.08l-4.16.42v2.62h16.71v-2.62l-4.2-.42c-.07-4.03-.08-8.08-.08-12.12v-1.77c0-4.17 0-8.31.08-12.37h5.38l.89 6.31h4.03l-.14-9.07H65.43zM143.85 233.37h5.58v-2.62l-5.58-.65v-20.46l17.16 23.83h4.13V204.56l4.15-.61v-2.62H156.42v2.62l5.58.62v16.46l-14.35-19.7h-11.02v2.65l3.36.29.72 1v24.9l-4.14.58v2.62h4.14z" />
        </g>
        <path fill={props.lightMode ? '#000' : '#fff'} d="M92.52 346.71c0 11.37 7.44 16.93 15.49 16.93s15.48-5.69 15.48-16.93c0-11.37-7.47-16.93-15.48-16.93-8.01 0-15.49 5.68-15.49 16.93zm22.32 0c0 8.09-1.93 14.11-6.83 14.11-4.91 0-6.85-6.01-6.85-14.11 0-8.06 1.94-14.14 6.85-14.14 4.9 0 6.83 6.08 6.83 14.14z" />
      </g>
      <g>
        <path fill={props.lightMode ? '#000' : '#fff'} d="M35.97 802.57h7.1v27.32h-7.1z" />
        <path
          transform="rotate(-45.001 16.034 823.515)"
          fill={props.lightMode ? '#000' : '#fff'}d="M12.5 809.85h7.1v27.32h-7.1z"
        />
        <path
          transform="rotate(45.001 63.004 823.514)"
          fill={props.lightMode ? '#000' : '#fff'}d="M59.45 809.85h7.1v27.32h-7.1z"
        />
        <path
          className="prefix__st01315"
          d="M114.52 931.86v39.64h22.71v-53.87h-52.5V971.5h22.7v-39.64z"
        />
        <path fill={props.lightMode ? '#000' : '#fff'} d="M137.23 910.53h-59.6v68.07H144.33v-68.07h-7.1zm0 60.97h-22.7v-39.64h-7.1v39.64h-22.7v-53.87h52.5v53.87zM179.11 895.14h7.1v60.91h-7.1z" />
        <path fill={props.lightMode ? '#000' : '#fff'}d="M208.9 872.59h-59.6V978.6H164.04c.41 9.94 8.57 17.88 18.62 17.88 10.04 0 18.21-7.94 18.62-17.88H216.02V872.59h-7.12zm-26.25 116.36c-6.14 0-11.13-4.99-11.13-11.13 0-6.14 4.99-11.13 11.13-11.13 6.14 0 11.13 4.99 11.13 11.13.01 6.14-4.99 11.13-11.13 11.13zm26.25-17.45h-8.72c-2.59-7.19-9.45-12.33-17.53-12.33-8.08 0-14.94 5.15-17.53 12.33h-8.72v-91.81h52.5v91.81zM137.23 872.59H8.94v32.96H37.31V972.25c-.72 1.99-1.13 4.12-1.13 6.35 0 10.3 8.35 18.65 18.65 18.65 10.3 0 18.65-8.35 18.65-18.65 0-1.94-.3-3.8-.84-5.56V905.55H144.32v-32.96h-7.09zm-82.4 117.14c-6.14 0-11.13-4.99-11.13-11.13 0-.12.01-.24.02-.36.09-2.73 1.16-5.2 2.87-7.1 2.04-2.25 4.98-3.68 8.25-3.68 3.27 0 6.21 1.43 8.25 3.68 1.71 1.89 2.78 4.37 2.87 7.1 0 .12.02.24.02.36-.02 6.13-5.01 11.13-11.15 11.13zm82.4-91.28H65.55v64.89c-3.03-2.14-6.72-3.4-10.72-3.4-3.86 0-7.45 1.17-10.43 3.18V898.44H16.04v-18.76h121.18v18.77z" />
        <path fill={props.lightMode ? '#000' : '#fff'}d="M8.98 923.22L0 943.76v34.84h33.37v-55.38H8.98zm18.37 49.36H6.02v-3.02h3.44v-6.95H6.02v-11.7h21.33v21.67zm0-29.47h-4.34v-8.74H10.68l2.24-5.13h14.43v13.87z" />
        <g>
          <path fill={props.lightMode ? '#000' : '#fff'}d="M55.11 869.88H23.93l4.41-34.32H50.7l4.41 34.32zm-24.34-6.01h17.5l-2.86-22.28H33.63l-2.86 22.28z" />
        </g>
      </g>
    </svg>
  )
}

export default SvgComponent
