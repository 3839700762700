import * as React from "react"

function Illustrator(props) {
  return (
    <svg
      id="prefix__Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 360 360"
      xmlSpace="preserve"
      {...props}
    >
      <style>{".prefix__st1{fill:#ff9a00}"}</style>
      <g id="prefix__Layer_2_1_">
        <g id="prefix__Surfaces">
          <g id="prefix__Drawing_Surface">
            <path
              d="M63.75 4.5h232.5C331.5 4.5 360 33 360 68.25v223.5c0 35.25-28.5 63.75-63.75 63.75H63.75C28.5 355.5 0 327 0 291.75V68.25C0 33 28.5 4.5 63.75 4.5z"
              fill="#300"
              id="prefix__Outline_no_shadow"
            />
          </g>
        </g>
        <g id="prefix__Outlined_Mnemonics_Logos">
          <g id="prefix__Ai">
            <path
              fill="#ff9a00"
              d="M174.45 215.1h-55.8l-11.4 35.25c-.3 1.35-1.5 2.25-2.85 2.1H76.2c-1.65 0-2.1-.9-1.65-2.7l48.3-139.05c.45-1.5.9-3.15 1.5-4.95.6-3.15.9-6.45.9-9.75-.15-.75.45-1.5 1.2-1.65h38.85c1.2 0 1.8.45 1.95 1.2L222 250.05c.45 1.65 0 2.4-1.5 2.4h-31.35c-1.05.15-2.1-.6-2.4-1.65l-12.3-35.7zm-47.1-30.45h38.1c-.9-3.15-2.1-6.9-3.45-10.8-1.35-4.05-2.7-8.4-4.05-12.9-1.5-4.65-2.85-9.15-4.35-13.8s-2.85-9-4.05-13.35c-1.2-4.2-2.25-8.1-3.3-11.7h-.3c-1.35 6.45-3 12.9-5.1 19.35-2.25 7.2-4.5 14.7-6.9 22.2-2.1 7.65-4.35 14.7-6.6 21zM254.7 120c-4.95.15-9.75-1.8-13.35-5.25-3.45-3.75-5.25-8.7-5.1-13.8-.15-5.1 1.8-9.9 5.4-13.35 3.6-3.45 8.4-5.25 13.35-5.25 5.85 0 10.35 1.8 13.65 5.25 3.3 3.6 5.1 8.4 4.95 13.35.15 5.1-1.65 10.05-5.25 13.8-3.45 3.6-8.55 5.55-13.65 5.25zm-16.8 130.2V134.7c0-1.5.6-2.1 1.95-2.1h29.7c1.35 0 1.95.75 1.95 2.1v115.5c0 1.65-.6 2.4-1.95 2.4h-29.4c-1.5 0-2.25-.9-2.25-2.4z"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default Illustrator;