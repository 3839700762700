import * as React from "react"

function RocketShip(props) {
  return (
    <svg
      id="prefix__Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 360 360"
      xmlSpace="preserve"
      {...props}
    >
      <path
        d="M265.42 229.57c-41.11 16.21-54.44 59.4-14.67 82.09 25 14.26 49.6 8.63 62.17 34.42 3.39-13.33-5.75-32.05-18.43-40.56 31.2 3.89 48.08 44.65 47.14 54.48 6.36-39.2-.97-60.23-35.35-81.22 18.62-12.54 47.51 27.72 47.51 27.72 1.78-31.08-22.08-103.09-88.37-76.93z"
        fill="#ffa84d"
      />
      <path
        d="M294.05 239.27c-34.83-1.38-51.2 21.07-52.03 33.27-.67 9.95 3.36 22.85 13.5 27.9 4.62 2.33 13.21 2.51 18.11 4.05 4.6 1.44 7.8 4.25 11.41 7.11-1.9-7.78-7.44-11.91-13.29-15.66 6.63-7.7 40.13 5.19 46.68 9.75-7.17-12.68-19.02-21.21-32.76-30.16 13.78-13.78 38.47-6.81 47.63-4.32-5.38-13.68-15.63-31.02-39.25-31.94z"
        fill="#ffc445"
      />
      <path
      fill="#d8d8d8"
        d="M148.38 240.95l-1.78 73.32c-.53 21.9 10.78 26.3 25.18 9.74l41.47-47.73-64.87-35.33zM247.08 142.27l73.3-1.8c21.92-.53 26.3 10.81 9.75 25.18l-47.73 41.47-35.32-64.85z"
      />
      <path
        d="M304.66 205.72c-5.33-8.65-13.49-20.4-24.35-33.45-10.82-13.07-24.33-27.45-39.87-41.8-7.78-7.15-16.04-14.36-24.68-21.39-8.65-7.05-17.7-13.92-26.9-20.67a804.37 804.37 0 00-28.04-19.51c-9.44-6.26-18.94-12.18-28.3-17.77-18.72-11.19-36.98-20.8-53.27-28.43-8.14-3.83-15.76-7.15-22.65-9.97-1.72-.71-3.4-1.38-5.02-2.03-1.64-.63-3.22-1.24-4.74-1.82s-2.98-1.15-4.36-1.68c-1.42-.51-2.78-.99-4.07-1.44-2.55-.93-4.9-1.72-6.93-2.37-2.03-.67-3.79-1.22-5.21-1.66S23.7 1 22.95.78C22.2.57 21.8.47 21.8.47c-2.09-.59-4.42-.65-6.67 0-6.56 1.84-10.39 8.65-8.55 15.2 0 0 .12.38.34 1.15.22.75.49 1.88.95 3.3.45 1.44.97 3.22 1.66 5.23.67 2.04 1.44 4.39 2.37 6.93.45 1.28.93 2.65 1.44 4.07.53 1.4 1.09 2.84 1.66 4.36.59 1.52 1.2 3.1 1.84 4.76.65 1.6 1.32 3.28 2.01 5.02a516.89 516.89 0 009.96 22.63c7.64 16.29 17.26 34.56 28.42 53.28 5.59 9.36 11.53 18.88 17.79 28.28 6.26 9.44 12.8 18.84 19.51 28.08 6.75 9.16 13.63 18.23 20.67 26.88 7.03 8.65 14.22 16.9 21.39 24.68 14.36 15.54 28.73 29.05 41.8 39.89 13.05 10.84 24.82 19.02 33.45 24.33 2.29 1.42 4.36 2.63 6.14 3.65l90.32-90.32c-1.02-1.81-2.24-3.87-3.64-6.15z"
        fill="#bababa"
      />
      <path
      fill="#d8d8d8"
        d="M201.26 190.63c-7.39-4.91-9.42-2.88-4.5 4.51l19.79 29.78c4.92 7.41 15.6 16.33 23.75 19.85l3.73 1.6c8.18 3.51 11.97-.28 8.45-8.43l-1.62-3.73c-3.5-8.16-12.42-18.84-19.83-23.78l-29.77-19.8zM177.17 113.52c15.9 15.89 15.9 41.65 0 57.52-15.88 15.9-41.63 15.9-57.52 0-15.88-15.88-15.88-41.63 0-57.52 15.9-15.88 41.65-15.88 57.52 0z"
      />
      <g>
        <path
          d="M122.39 142.29c0 14.36 11.65 26.01 26.03 26.01v-52.01c-14.38-.01-26.03 11.63-26.03 26z"
          fill="#a5e6f7"
        />
      </g>
      <g>
        <path
          d="M148.42 168.3c14.35 0 26-11.65 26-26.01 0-14.38-11.65-26.01-26-26.01v52.02z"
          fill="#6fdaf7"
        />
      </g>
    </svg>
  )
}

export default RocketShip;
