import * as React from "react"

function Expo(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 360 360" {...props}>
      <path
        d="M229.28 25.84C214.31 3.03 210.74 0 180.27 0h-.72c-30.48 0-33.86 3.03-49.01 25.84-14.08 21.39-153.8 277.31-153.8 284.44 0 10.34 2.67 20.14 12.3 34.04 9.8 14.08 26.73 21.92 39.03 9.45 8.38-8.38 98.02-162.89 141.33-221.35 5.17-7.31 15.86-7.31 21.21 0 43.31 58.46 132.95 212.97 141.33 221.35 12.3 12.48 29.23 4.63 39.03-9.45 9.62-13.9 12.3-23.7 12.3-34.04-.19-7.13-139.91-263.23-153.99-284.44z"
        fill="#1173b6"
      />
    </svg>
  )
}

export default Expo;