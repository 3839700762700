import * as React from "react"

function Javascript(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 360 360" {...props}>
      <path d="M0 0h360v360H0V0z" fill="#f7df1e" />
      <path d="M94.66 300.84l27.55-16.67c5.31 9.42 10.15 17.4 21.75 17.4 11.12 0 18.13-4.35 18.13-21.26V165.27h33.83v115.51c0 35.04-20.54 50.99-50.51 50.99-27.07 0-42.78-14.02-50.75-30.93M214.29 297.22l27.55-15.95c7.25 11.84 16.68 20.54 33.35 20.54 14.02 0 22.96-7.01 22.96-16.67 0-11.6-9.18-15.71-24.65-22.47l-8.46-3.63c-24.41-10.39-40.6-23.44-40.6-50.99 0-25.37 19.33-44.71 49.54-44.71 21.51 0 36.97 7.49 48.09 27.07l-26.34 16.91c-5.8-10.39-12.08-14.5-21.75-14.5-9.91 0-16.19 6.28-16.19 14.5 0 10.15 6.28 14.26 20.78 20.54l8.46 3.62c28.76 12.33 44.95 24.89 44.95 53.16 0 30.45-23.92 47.12-56.06 47.12-31.43.01-51.73-14.97-61.63-34.54" />
    </svg>
  )
}

export default Javascript;