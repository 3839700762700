import * as React from "react"

function Contact(props) {
  return (
    <svg
      id="prefix__Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 360 360"
      xmlSpace="preserve"
      {...props}
    >
      <style>{".prefix__st3{fill:#303030}.prefix__st4{fill:#c9c9c9}"}</style>
      <path
        d="M348.62 116.98c0 8.7-7.11 15.81-15.81 15.81H211.15c-8.7 0-15.81-7.11-15.81-15.81v-64.9c0-8.7 7.11-15.81 15.81-15.81h121.66c8.7 0 15.81 7.11 15.81 15.81v64.9z"
        fill="#ff6e6e"
      />
      <path
        d="M348.62 213.48c0 8.7-7.11 15.81-15.81 15.81H211.15c-8.7 0-15.81-7.11-15.81-15.81v-64.9c0-8.7 7.11-15.79 15.81-15.79h121.66c8.7 0 15.81 7.09 15.81 15.79v64.9z"
        fill="#fff170"
      />
      <path
        d="M348.62 310.01c0 8.7-7.11 15.79-15.81 15.79H211.15c-8.7 0-15.81-7.09-15.81-15.79v-64.92c0-8.68 7.11-15.79 15.81-15.79h121.66c8.7 0 15.81 7.11 15.81 15.79v64.92z"
        fill="#adff7d"
      />
      <path
        className="prefix__st3"
        d="M57.37 0c-19.7 0-35.82 16.1-35.82 35.8v288.38c0 19.7 16.12 35.82 35.82 35.82h32.65V0H57.37z"
      />
      <path
        className="prefix__st4"
        d="M280.54 0H90.02v360h190.52c19.7 0 35.82-16.12 35.82-35.82V35.8c0-19.7-16.12-35.8-35.82-35.8zM55.78 70.83H19.26c-4.37 0-7.89-3.53-7.89-7.9s3.52-7.91 7.89-7.91h36.52c4.37 0 7.91 3.54 7.91 7.91s-3.54 7.9-7.91 7.9zM55.78 116.65H19.26c-4.37 0-7.89-3.53-7.89-7.88 0-4.37 3.52-7.91 7.89-7.91h36.52c4.37 0 7.91 3.54 7.91 7.91 0 4.34-3.54 7.88-7.91 7.88zM55.78 162.49H19.26c-4.37 0-7.89-3.54-7.89-7.91s3.52-7.91 7.89-7.91h36.52c4.37 0 7.91 3.54 7.91 7.91s-3.54 7.91-7.91 7.91zM55.78 208.3H19.26c-4.37 0-7.89-3.54-7.89-7.91 0-4.35 3.52-7.89 7.89-7.89h36.52c4.37 0 7.91 3.54 7.91 7.89 0 4.37-3.54 7.91-7.91 7.91zM55.78 254.12H19.26c-4.37 0-7.89-3.52-7.89-7.89 0-4.37 3.52-7.91 7.89-7.91h36.52c4.37 0 7.91 3.54 7.91 7.91s-3.54 7.89-7.91 7.89zM55.78 299.95H19.26c-4.37 0-7.89-3.54-7.89-7.91 0-4.35 3.52-7.89 7.89-7.89h36.52c4.37 0 7.91 3.54 7.91 7.89 0 4.38-3.54 7.91-7.91 7.91z"
      />
      <g>
        <path
          className="prefix__st3"
          d="M297.1 224.17l-52.33-52.33-19.12 19.1-3.87 3.89-.83.83-3.05 3.04c-1.66.29-7.39-1.3-13.48-7.38l-17.69-17.69c-6.09-6.09-7.67-11.79-7.38-13.48l3.05-3.05 23.82-23.8-52.35-52.35-19.1 19.1-4.7 4.7-12.2 12.2c-5.55 5.57-8.62 12.97-8.62 20.86 0 7.89 3.07 15.31 8.62 20.86L219.4 260.18c5.74 5.76 13.28 8.62 20.84 8.62s15.12-2.86 20.86-8.62L278 243.3l19.1-19.13z"
        />
      </g>
    </svg>
  )
}

export default Contact;