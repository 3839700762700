import * as React from "react"

function Typescript(props) {
  return (
    <svg
      id="prefix__Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 360 360"
      xmlSpace="preserve"
      {...props}
    >
      <style>{".prefix__st1{fill:#fff}"}</style>
      <path fill="#007acc" d="M0 180v180h360V0H0z" />
      <path
        className="prefix__st1"
        d="M79.61 181.19l-.11 14.74h46.86v133.14h33.14V195.93h46.86v-14.46c0-8-.17-14.69-.4-14.86-.17-.23-28.69-.34-63.26-.29l-62.91.17-.18 14.7zM290.48 166.09c9.14 2.29 16.11 6.34 22.51 12.97 3.31 3.54 8.23 10 8.63 11.54.11.46-15.54 10.97-25.03 16.86-.34.23-1.71-1.26-3.26-3.54-4.63-6.74-9.49-9.66-16.91-10.17-10.91-.74-17.94 4.97-17.89 14.51 0 2.8.4 4.46 1.54 6.74 2.4 4.97 6.86 7.94 20.86 14 25.77 11.09 36.8 18.4 43.66 28.8 7.66 11.6 9.37 30.11 4.17 43.89-5.71 14.97-19.89 25.14-39.83 28.51-6.17 1.09-20.8.91-27.43-.29-14.46-2.57-28.17-9.71-36.63-19.09-3.31-3.66-9.77-13.2-9.37-13.89.17-.23 1.66-1.14 3.31-2.11 1.6-.91 7.66-4.4 13.37-7.71l10.34-6 2.17 3.2c3.03 4.63 9.66 10.97 13.66 13.09 11.49 6.06 27.26 5.2 35.03-1.77 3.31-3.03 4.69-6.17 4.69-10.8 0-4.17-.51-6-2.69-9.14-2.8-4-8.51-7.37-24.74-14.4-18.57-8-26.57-12.97-33.89-20.86-4.23-4.57-8.23-11.89-9.89-18-1.37-5.09-1.71-17.83-.63-22.97 3.83-17.94 17.37-30.46 36.91-34.17 6.37-1.2 21.12-.74 27.34.8z"
      />
    </svg>
  )
}

export default Typescript;