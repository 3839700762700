import * as React from "react"

function Basketball(props) {
  return (
    <svg
      id="prefix__Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 360 360"
      xmlSpace="preserve"
      {...props}
    >
      <path
        d="M117.54 232.64L170.19 180 47.86 57.66C18.49 89.36 2.64 129.12.31 169.54c29.37-4.57 77.96 25.33 117.23 63.1z"
        fill="#ff804a"
      />
      <path
        d="M.03 183.77c.9 42.68 16.84 85.11 47.83 118.55l59.86-59.85C62.33 198.69 18.39 178.31.03 183.77zM232.66 117.53C194.86 78.28 164.99 29.67 169.53.3c-40.4 2.35-80.18 18.18-111.85 47.55l122.31 122.33 52.67-52.65zM242.47 127.35L189.82 180l122.33 122.33c29.36-31.69 45.2-71.45 47.55-111.85-29.36 4.54-77.98-25.34-117.23-63.13z"
        fill="#ff804a"
      />
      <path
        d="M359.96 176.22c-.88-42.68-16.84-85.11-47.81-118.56l-59.87 59.87c45.4 43.77 89.35 64.17 107.68 58.69zM242.47 107.71l59.87-59.87C268.89 16.86 226.47.92 183.78.04c-5.48 18.34 14.92 62.28 58.69 107.67zM117.54 252.28l-59.86 59.86c33.44 30.99 75.85 46.93 118.55 47.83 5.48-18.36-14.92-62.3-58.69-107.69z"
        fill="#ff804a"
      />
      <path
        d="M127.34 242.48c37.79 39.25 67.67 87.85 63.13 117.21 40.42-2.33 80.18-18.19 111.87-47.55L179.98 189.81l-52.64 52.67z"
        fill="#ff804a"
      />
      <g>
        <path
          d="M312.15 302.33L189.82 180l52.65-52.65c39.25 37.8 87.86 67.67 117.23 63.13.26-4.76.36-9.52.26-14.26-18.33 5.48-62.28-14.92-107.67-58.69l59.87-59.87c-1.58-1.68-3.16-3.37-4.8-5.01-1.66-1.64-3.33-3.22-5.01-4.81l-59.87 59.87C198.7 62.32 178.3 18.38 183.78.04c-4.74-.1-9.52 0-14.26.26-4.55 29.37 25.33 77.98 63.13 117.23l-52.67 52.65L57.68 47.84c-1.71 1.58-3.37 3.16-5.02 4.81s-3.24 3.33-4.8 5.01L170.19 180l-52.65 52.65C78.27 194.88 29.68 164.98.31 169.54c-.28 4.74-.36 9.5-.28 14.23 18.35-5.46 62.3 14.92 107.69 58.71l-59.86 59.85c1.56 1.71 3.16 3.37 4.8 5.02 1.64 1.64 3.31 3.24 5.02 4.8l59.86-59.86c43.77 45.39 64.17 89.34 58.69 107.69 4.74.09 9.5 0 14.23-.28 4.55-29.37-25.33-77.96-63.13-117.21l52.65-52.67 122.35 122.33c1.68-1.56 3.35-3.16 5.01-4.8 1.65-1.65 3.23-3.32 4.81-5.02z"
          fill="#423a3b"
        />
      </g>
    </svg>
  )
}

export default Basketball;