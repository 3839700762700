import * as React from "react"

function StackOfBooks(props) {
  return (
    <svg
      id="prefix__Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 360 360"
      xmlSpace="preserve"
      {...props}
    >
      <style>{".prefix__st1{fill:#fff}.prefix__st2{fill:#f0f0f0}"}</style>
      <path
        d="M27.04 285.26c-6.59-8.55-4.72-23.31 1.03-34.6l-7.58-2.68c-4.51 4.05-6.78 9.61-6.85 16.18-.09 7.26-1.05 18.94 5.49 26.31 1.3 1.47 2.91 2.77 4.86 3.82l7.58-4.95c-1.83-1.12-3.32-2.5-4.53-4.08z"
        fill="#2e9edd"
      />
      <path
        className="prefix__st1"
        d="M180.28 314.73c.68-3.32 2.04-6.26 3.87-8.91-1.48-.2-2.9-.51-4.17-.98l-19.71-6.98L97 275.41l-1.74-.62-.32-.1-.69-.25-3.5-1.24-1.58-.55-27.52-10.07-33.58-11.91c-5.75 11.29-7.61 26.05-1.03 34.6 1.21 1.58 2.7 2.97 4.53 4.07.43.25.87.48 1.33.71.37.19.78.37 1.19.53l.04.04c.69.27 1.42.51 2.18.73l86.61 31.03 67.25 23.87c-13.35-6.38-11.11-25.43-9.89-31.52z"
      />
      <path
        className="prefix__st2"
        d="M339.7 238.1c-.98-1.67-1.72-3.28-2.27-4.87-1.69-4.77-1.62-9.12-.92-12.57.59-2.89 1.53-5.7 2.75-8.36l-21.34 14.01-4.94 3.23-3.87 2.52-71.37 46.84-7.55 4.95-28.25 18.54c-4.51 2.95-11.74 4.23-17.79 3.43-1.83 2.65-3.2 5.59-3.87 8.91-1.23 6.09-3.46 25.14 9.89 31.5l154.69-101.47c-2.18-2.25-3.86-4.47-5.16-6.66z"
      />
      <path
        d="M356.35 243.43c.05 1.81-1.19 3.76-3.75 5.43L201.94 347.7c-5.61 3.68-15.45 4.78-21.96 2.47l-65.8-23.35-88.13-31.57 8.08-4.65-.04-.04-8.04 4.69c-.23-.09-.43-.2-.66-.28-.48-.23-.94-.44-1.4-.69-1.95-1.05-3.57-2.34-4.86-3.82-6.53-7.37-5.57-19.05-5.49-26.31.07-6.57 2.34-12.13 6.85-16.18l7.58 2.68 33.58 11.91 27.52 10.07 1.58.55 3.5 1.24.69.25.32.1 1.74.62 63.28 22.46 19.71 6.98c1.28.46 2.7.78 4.17.98 6.05.8 13.28-.48 17.79-3.43l28.25-18.54 7.55-4.95 71.37-46.84 3.87-2.52 4.94-3.23 21.34-14.01 13.35-8.75c2.47-1.63 3.71-3.48 3.75-5.26.05-2.22 0-3.8 0-8.11.02 1.15-.48 2.33-1.49 3.48l-.18.18c-.55.59-1.24 1.17-2.08 1.72l-.78.51L310 223.51l-5.2 3.43-3.59 2.34-87.16 57.17-9.21 6.05-2.89 1.9c-2.7 1.78-6.39 2.93-10.21 3.41-1.07.14-2.13.23-3.2.25-1.3.04-2.57-.04-3.82-.16-1.69-.2-3.3-.53-4.74-1.05l-11.79-4.17-62.73-22.25-1.63-.59-.39-.14-1.3-.46-3.5-1.24-1.58-.55-6.94-2.47-47.44-17.13-10.55-3.73-7.42-2.65-7.92-2.81C9.63 244.59 3.65 254 3.62 264.81c-.03 9.27-.82 29.72 13.17 35.78l163.18 58.18c6.52 2.33 16.35 1.22 21.96-2.45l150.66-98.84c2.45-1.6 3.68-3.44 3.75-5.18.1-2.25.01-3.53.01-8.87z"
        fill="#38a5e8"
      />
      <path
        d="M350.92 184.72l-136.69-48.49c-3.25-1.15-6.55-1.44-9.36.41L16.8 238.67l7.92 2.81 7.42 2.65 10.55 3.73 47.44 17.13 6.94 2.47 1.58.55 3.5 1.24 1.3.46.39.14 1.63.59 62.73 22.25 11.79 4.17c1.44.51 3.05.85 4.74 1.05 1.24.12 2.52.2 3.82.16 1.07-.02 2.13-.11 3.2-.25 3.82-.48 7.51-1.63 10.21-3.41l2.89-1.9 9.21-6.05 87.16-57.17 3.59-2.34 5.2-3.43 41.83-27.43.78-.51c.83-.55 1.53-1.14 2.08-1.72l.18-.18c1.01-1.15 1.51-2.33 1.49-3.48-.03-2.18-1.88-4.21-5.45-5.48z"
        fill="#42aefa"
      />
      <path
        d="M350.92 238.02l-13.49-4.78c.55 1.58 1.3 3.2 2.27 4.87 1.3 2.18 2.98 4.4 5.17 6.66l-154.7 101.46-67.25-23.87-86.61-31.03c-.71-.2-1.42-.44-2.08-.69-.43-.2-1.01-.44-1.33-.6-.46-.23-.9-.46-1.33-.71l-7.58 4.95c.46.25.92.46 1.4.69.23.09.43.2.66.28h.02-.02l88.13 31.57 65.8 23.35c6.52 2.31 16.35 1.21 21.96-2.47l150.66-98.84c2.56-1.67 3.8-3.62 3.75-5.43-.05-2.15-1.9-4.16-5.43-5.41z"
        fill="#42b1ff"
      />
      <g>
        <path
          d="M27.04 221.47c-6.59-8.56-4.72-23.31 1.03-34.6l-7.58-2.68c-4.51 4.05-6.78 9.61-6.85 16.19-.09 7.24-1.05 18.93 5.49 26.29 1.3 1.48 2.91 2.75 4.86 3.82l7.58-4.97c-1.83-1.1-3.32-2.47-4.53-4.05z"
          fill="#b3b3b3"
        />
        <path
          className="prefix__st1"
          d="M180.28 250.94c.68-3.32 2.04-6.27 3.89-8.91-1.49-.21-2.91-.51-4.19-.98l-19.71-6.97L97 211.61l-1.74-.62-.32-.11-.69-.25-3.5-1.24-1.58-.55-27.52-10.07-33.58-11.91c-5.75 11.29-7.61 26.05-1.03 34.6 1.21 1.58 2.7 2.95 4.53 4.05.43.26.87.5 1.33.73.37.2.78.37 1.17.53l.05.04c.69.27 1.42.51 2.18.73l86.61 31.03 67.27 23.86c-13.36-6.33-11.12-25.4-9.9-31.49z"
        />
        <path
          className="prefix__st2"
          d="M339.7 174.31c-.98-1.67-1.72-3.28-2.27-4.86-1.69-4.78-1.62-9.13-.92-12.57.59-2.91 1.53-5.7 2.75-8.36l-21.34 14.01-4.94 3.23-3.87 2.54-71.37 46.82-7.55 4.95-28.25 18.54c-4.51 2.95-11.74 4.22-17.77 3.43-1.85 2.65-3.21 5.59-3.89 8.91-1.23 6.09-3.46 25.16 9.91 31.5l154.67-101.47c-2.18-2.26-3.86-4.5-5.16-6.67z"
        />
        <path
          d="M356.35 179.64c.05 1.83-1.19 3.76-3.75 5.45l-150.66 98.82c-5.61 3.68-15.45 4.78-21.96 2.47l-65.8-23.35-88.12-31.57 8.06-4.65-.05-.04-8.01 4.68c-.25-.09-.44-.19-.67-.28-.48-.21-.94-.44-1.4-.69-1.95-1.07-3.57-2.34-4.86-3.82-6.53-7.37-5.57-19.05-5.49-26.29.07-6.59 2.34-12.14 6.85-16.19l7.58 2.68 33.58 11.91 27.52 10.07 1.58.55 3.5 1.24.69.25.32.11 1.74.62 63.28 22.46 19.71 6.97c1.28.46 2.7.77 4.19.98 6.04.8 13.26-.48 17.77-3.43l28.25-18.54 7.55-4.95 71.37-46.82 3.87-2.54 4.94-3.23 21.34-14.01 13.35-8.75c2.49-1.63 3.71-3.48 3.75-5.26.05-2.22 0-3.8 0-8.11.02 1.15-.48 2.34-1.49 3.48l-.18.18c-.55.59-1.24 1.17-2.08 1.72l-.78.51L310 159.72l-5.2 3.41-3.59 2.36-87.14 57.17-9.23 6.05-2.89 1.9c-2.7 1.78-6.39 2.93-10.21 3.41-1.07.14-2.13.21-3.2.25-1.3.02-2.57-.04-3.82-.18-1.69-.18-3.3-.51-4.74-1.03l-11.79-4.19-62.73-22.23-1.63-.59-.39-.14-1.31-.46-3.48-1.24-1.58-.55-6.94-2.47-47.44-17.14-10.55-3.74-7.42-2.63-7.92-2.81c-7.16 5.93-13.14 15.34-13.17 26.15-.03 9.27-.82 29.72 13.17 35.78l163.18 58.18c6.52 2.31 16.35 1.23 21.96-2.47l150.66-98.82c2.45-1.62 3.68-3.44 3.75-5.18.09-2.25 0-3.53 0-8.87z"
          fill="#bdbdbd"
        />
        <path
          d="M350.92 120.92L214.23 72.44c-3.25-1.15-6.55-1.44-9.36.41L16.8 174.88l7.92 2.81 7.42 2.63 10.55 3.74 47.44 17.14 6.94 2.47 1.58.55 3.48 1.24 1.31.46.39.14 1.63.59 62.73 22.23 11.79 4.19c1.44.52 3.05.86 4.74 1.03a30 30 0 003.82.18c1.07-.04 2.13-.11 3.2-.25 3.82-.48 7.51-1.63 10.21-3.41l2.89-1.9 9.23-6.05 87.14-57.17 3.59-2.36 5.2-3.41 41.83-27.43.78-.51c.83-.55 1.53-1.14 2.08-1.72l.12-.12c1.07-1.19 1.56-2.38 1.54-3.53-.01-2.2-1.86-4.23-5.43-5.5z"
          fill="#cfcfcf"
        />
        <path
          d="M350.92 174.24l-13.49-4.79c.55 1.58 1.3 3.2 2.27 4.86 1.3 2.17 2.98 4.4 5.17 6.66L190.19 282.44l-67.27-23.86-86.61-31.03c-.71-.2-1.42-.44-2.08-.71-.43-.16-1.01-.43-1.33-.59-.46-.23-.9-.46-1.33-.73l-7.58 4.97c.46.25.92.48 1.4.69.23.09.43.2.67.28l88.12 31.57 65.8 23.35c6.52 2.31 16.35 1.21 21.96-2.47l150.66-98.82c2.56-1.69 3.8-3.62 3.75-5.45-.05-2.15-1.9-4.16-5.43-5.4z"
          fill="#d4d4d4"
        />
      </g>
      <g>
        <path
          d="M27.04 157.57c-6.59-8.58-4.72-23.33 1.03-34.62l-7.58-2.68c-4.51 4.05-6.78 9.61-6.85 16.19-.09 7.24-1.05 18.93 5.49 26.29 1.3 1.48 2.91 2.77 4.86 3.82l7.58-4.95c-1.83-1.1-3.32-2.47-4.53-4.05z"
          fill="#ffa61c"
        />
        <path
          className="prefix__st1"
          d="M180.28 187.02c.68-3.32 2.04-6.27 3.87-8.91-1.48-.2-2.9-.51-4.17-.98l-19.71-6.97L97 147.7l-1.74-.6-.32-.12-.69-.25-24.07-8.84-1.58-.55-6.94-2.47-33.58-11.91c-5.75 11.29-7.61 26.05-1.03 34.62 1.21 1.58 2.7 2.95 4.53 4.05.43.25.87.5 1.33.73.37.18.78.36 1.19.51l.04.04c.69.28 1.42.51 2.18.73l86.61 31.03 67.25 23.86c-13.36-6.35-11.12-25.42-9.9-31.51z"
        />
        <path
          className="prefix__st2"
          d="M339.7 110.4a26.68 26.68 0 01-2.27-4.86c-1.69-4.78-1.62-9.13-.92-12.57.59-2.89 1.53-5.7 2.75-8.36l-21.34 14.01-4.94 3.23-3.87 2.54-71.37 46.82-7.55 4.95-28.25 18.54c-4.51 2.95-11.74 4.22-17.79 3.43-1.83 2.65-3.2 5.59-3.87 8.91-1.23 6.09-3.46 25.16 9.89 31.5l154.69-101.47c-2.18-2.26-3.86-4.49-5.16-6.67z"
        />
        <path
          d="M356.35 115.72c.05 1.83-1.19 3.76-3.75 5.45l-150.66 98.82c-5.61 3.68-15.45 4.78-21.96 2.47l-65.8-23.35-88.13-31.57 8.08-4.65-.04-.04-8.04 4.68c-.23-.09-.43-.19-.66-.28-.48-.21-.94-.44-1.4-.69-1.95-1.05-3.57-2.34-4.86-3.82-6.53-7.37-5.57-19.05-5.49-26.29.07-6.59 2.34-12.14 6.85-16.19l7.58 2.68 33.58 11.91 6.94 2.47 1.58.55 24.07 8.84.69.25.32.12 1.74.6 63.28 22.46 19.71 6.97c1.28.46 2.7.78 4.17.98 6.05.8 13.28-.48 17.79-3.43l28.25-18.54 7.55-4.95 71.37-46.82 3.87-2.54 4.94-3.23 21.34-14.01 13.35-8.75c2.47-1.63 3.71-3.48 3.75-5.26.05-2.22 0-3.8 0-8.11.02 1.15-.48 2.34-1.49 3.48l-.18.18c-.55.59-1.24 1.17-2.08 1.72l-.78.51L310 95.8l-5.2 3.43-3.59 2.34-87.16 57.17-9.21 6.05-2.89 1.9c-2.7 1.78-6.39 2.93-10.21 3.41-1.07.14-2.13.23-3.2.25-1.3.03-2.57-.02-3.82-.16-1.69-.2-3.3-.53-4.74-1.03l-11.79-4.19-62.73-22.25-1.63-.59-.39-.14-1.3-.46-3.5-1.23-1.58-.57-27.52-10.07-26.86-9.54-10.55-3.74-7.42-2.63-7.92-2.81c-7.16 5.91-13.14 15.34-13.17 26.15-.03 9.27-.82 29.72 13.17 35.78l163.18 58.18c6.52 2.33 16.35 1.23 21.96-2.45l150.66-98.84c2.45-1.6 3.68-3.44 3.75-5.18.1-2.23.01-3.51.01-8.86z"
          fill="#ffb026"
        />
        <path
          d="M350.92 57.01L193.67.92c-3.27-1.16-6.57-1.44-9.36.41L16.8 110.96l7.92 2.81 7.42 2.63 10.55 3.74 26.86 9.54 27.52 10.07 1.58.57 3.5 1.23 1.3.46.39.14 1.63.59 62.73 22.25 11.79 4.19c1.44.5 3.05.83 4.74 1.03 1.24.14 2.52.19 3.82.16 1.07-.02 2.13-.11 3.2-.25 3.82-.48 7.51-1.63 10.21-3.41l2.89-1.9 9.21-6.05 87.16-57.17 3.59-2.34 5.2-3.43 41.83-27.43.78-.51c.83-.55 1.53-1.14 2.08-1.72l.12-.12c1.07-1.19 1.56-2.38 1.54-3.53-.02-2.2-1.87-4.24-5.44-5.5z"
          fill="#ffc230"
        />
        <path
          d="M350.92 110.33l-13.49-4.79c.55 1.58 1.3 3.21 2.27 4.86 1.3 2.18 2.98 4.42 5.17 6.66l-154.7 101.46-67.25-23.86-86.61-31.03c-.71-.2-1.42-.44-2.08-.71-.43-.16-1.01-.43-1.33-.57-.46-.23-.9-.48-1.33-.73l-7.58 4.95c.46.25.92.48 1.4.69.23.09.43.2.66.28h.02-.02l88.13 31.57 65.8 23.35c6.52 2.31 16.35 1.21 21.96-2.47l150.66-98.82c2.56-1.69 3.8-3.62 3.75-5.45-.05-2.15-1.9-4.15-5.43-5.39z"
          fill="#ffc730"
        />
      </g>
    </svg>
  )
}

export default StackOfBooks;