import * as React from "react"

function PantryLogo(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 216 69.33" {...props}>
      <path
        d="M34.67 0C15.52 0 0 15.52 0 34.66s15.52 34.67 34.67 34.67 34.67-15.52 34.67-34.67S53.81 0 34.67 0zm0 61.78c-2.17 0-4.27-.26-6.29-.74V48.69c1.92.86 4.05 1.35 6.29 1.35 8.49 0 15.38-6.88 15.38-15.38 0-8.49-6.88-15.38-15.38-15.38-8.49 0-15.38 6.88-15.38 15.38V57C12.2 52.11 7.55 43.93 7.55 34.66c0-14.98 12.14-27.12 27.12-27.12s27.12 12.14 27.12 27.12c-.01 14.98-12.15 27.12-27.12 27.12zm-6.29-27.12c0-3.47 2.82-6.29 6.29-6.29s6.29 2.82 6.29 6.29-2.82 6.29-6.29 6.29c-3.48 0-6.29-2.81-6.29-6.29z"
        fill="#9e7ae9"
      />
      <path fill={props.lightMode ? '#000' : '#fff'} d="M92.08 18.37c-1.59 0-5.2.07-6.61.07v25.29h3.07v-9.72c1.26.11 2.78.14 3.79.14 6.18 0 9.1-3.83 9.1-7.95 0-3.93-2.13-7.83-9.35-7.83zm.14 12.9c-1.26 0-2.46-.07-3.69-.18v-9.75c1.19-.04 2.82-.07 3.83-.07 4.01 0 5.82 1.92 5.82 4.91 0 3.17-2.24 5.09-5.96 5.09zM111.36 27.11c-2.02 0-3.97.58-5.42 1.48l.76 2.31c1.3-.69 2.49-1.19 4.48-1.19 3.22 0 3.69 2.09 3.69 5.13-1.08-.8-2.78-1.05-4.05-1.05-3.5 0-6.14 1.84-6.14 5.17 0 3.14 2.02 5.02 4.99 5.02 2.67 0 4.23-1.12 5.2-2.35v2.1h2.75v-9.97c-.01-4.23-1.63-6.65-6.26-6.65zm3.51 12.18c-.83.98-2.06 2.1-4.19 2.1-1.88 0-2.93-1.01-2.93-2.57 0-1.88 1.73-2.67 3.47-2.67 1.41 0 2.64.36 3.65 1.08v2.06zM129.06 27.19c-2.13 0-4.19 1.05-5.31 2.28v-1.99h-2.89v16.26h2.89V31.99c1.05-1.19 2.75-2.1 4.59-2.1 3.03 0 4.59 1.59 4.59 4.99v8.85h2.89v-9.39c-.01-5.09-2.54-7.15-6.76-7.15zM144.83 41.31c-2.06 0-2.89-1.48-2.89-4.05v-7.08h5.96v-2.71h-5.96v-4.52h-2.89v14.6c0 4.15 1.45 6.47 5.09 6.47 1.81 0 3.29-.65 4.15-1.37l-.9-2.24c-.57.43-1.44.9-2.56.9zM154.44 29.39v-1.91h-2.89v16.26h2.89V32.21c.61-1.19 1.52-2.31 3.4-2.31.98 0 1.41.14 2.2.61l1.01-2.71c-.54-.4-1.19-.61-2.53-.61-2.06 0-3.25.97-4.08 2.2zM174.26 37.77c-.29.87-.54 1.7-.76 2.49-.33-.83-.61-1.63-.98-2.46l-4.77-10.33h-3.47l7.8 16.26-.36.87c-1.08 2.6-2.1 3.69-3.47 3.69-.65 0-1.26-.29-1.81-.54l-.94 2.2c.87.58 1.84 1.01 3.07 1.01 2.31 0 3.83-1.41 6.21-7.23l6.5-16.26h-3.29l-3.73 10.3zM186.61 39.87c-1.26 0-2.06.98-2.06 1.91 0 1.08.8 2.06 2.06 2.06 1.12 0 1.92-.98 1.92-2.06-.01-.94-.8-1.91-1.92-1.91zM192.13 27.47h2.89v16.26h-2.89zM193.65 19.63c-1.01 0-1.88.69-1.88 1.73 0 1.19.87 1.88 1.88 1.88.9 0 1.77-.69 1.77-1.88-.01-1.04-.87-1.73-1.77-1.73zM207.33 27.11c-5.2 0-8.67 3.61-8.67 8.49 0 4.88 3.47 8.49 8.67 8.49 5.2 0 8.67-3.61 8.67-8.49 0-4.87-3.47-8.49-8.67-8.49zm0 14.27c-3.25 0-5.6-2.38-5.6-5.78 0-3.43 2.42-5.78 5.6-5.78 3.25 0 5.6 2.38 5.6 5.78 0 3.47-2.35 5.78-5.6 5.78z" />
    </svg>
  )
}

export default PantryLogo;